import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'

const SpotRepairPage = () => {
  return (
    <Layout pageTitle="Spot Repair">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/cctv_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Spot Repair</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container className="section default-text">
          <Row md={2} xs={1}>
            <Col>
              <h3>Spot Repair</h3>
              <p>
                Spot repair or point repair lining is a resin and patch system that adheres to the inside of a pipe to fix structural defects and prevent further damage to pipe. This restores a pipes functionality without the need for excavation, offering a non-intrusive, environmentally friendly solution. At Wessuc, we use a UV Light Cured In Place Pipe system which uses UV light to cure the resin. The UV CIPP System has a cure time of 8 minutes.
              </p>
              <p>
                A spot repair is used when the integrity of a pipe is compromised by a defect. Applying a spot repair will increase the longevity of a pipe and lower its risk of failure. Spot repair goes hand in hand with cutting and grouting.
              </p>
                <Button href='/contact' variant="primary" className="shadow">Contact us for your spot repair needs</Button>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/grouting1.png"
              />
            </Col>
          </Row>
        </Container>
        <Container className="default-text">
          <Row md={2} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/grouting3.png"
              />
            </Col>
            <Col>
              <h3>Pipe Defects Include:</h3>
              <ul>
                <li>Ingress of soil and debris</li>
                <li>Defective pipe connections</li>
                <li>Cracks of pipes</li>
                <li>Hole</li>
                <li>Break</li>
                <li>Lateral blockage</li>
                <li>Infiltration</li>
                <li>Roots</li>
                <li>H2S corrosion</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container className="section text-center">
          <Row>
            <Col>
              <h3>What's in it for you?</h3>
              <p>
                At Wessuc, we put the comfort of your community first through our non-intrusive repair method. With UV liners that cure in only 8 minutes, our spot repair team can complete the repair quickly. Hours faster than other CIPP liners.
              </p>
              <p>
                Speed isn't the only benefit, spot repair is also a reliable fix, maintaining the integrity of your pipes for <strong>50+ years</strong>.
              </p>
              <StaticImage
                src="../../../media/cost-savings.png"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default SpotRepairPage